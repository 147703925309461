<template>
  <div class="item">
    <el-col :span="24" v-if="activityType == 1">
      <!-- 人气爆品 -->
      <div class="imgWrap" @click="jumpDetail(info)">
        <!-- <img v-if="info.quantity<Number(info.minOrderNum)" class="soldOut" alt="补货中" src="@/assets/soldOut.png" /> -->
        <img
          :alt="info.name | defaultName"
          :src="url ? url + picSuffix : defaultImage"
        />
        <!-- <img  class="AdvanceSaleBg" src="@/assets/AdvanceSale.png" alt="">
        <div class="bottomRightBox">
            预计{{info.presellDeliveryDate }}发货
        </div> -->
      </div>
      <h3 class="shopName" :title="info.name">
        {{ info.name | defaultName }}
        <span v-if="info.presellSubTitle">[{{ info.presellSubTitle }}]</span>
      </h3>
      <p class="msg">
        <strong>{{ info.specification }}</strong>
        <strong>{{ info.partSpecification }}</strong>
      </p>
      <span class="AddBtn" @click="jumpDetail(info)">立即购买</span>
      <div class="priceBox">
        <span class="price">
          <!-- {{ info.auditUserStatus===0?"***":info.price  | capitalize }} -->
          ￥{{
            Number(numPriceArr[numPriceArr.length - 1].price).toFixed(2)
          }}
          起
        </span>
      </div>
      <div class="priceBox">
        <del class="delPrice">
          {{
            info.auditUserStatus === 0 ? "***" : info.originalPrice | capitalize
          }}
        </del>
      </div>
    </el-col>
    <el-col :span="24" v-else>
      <!-- 超级秒杀 -->
      <div class="imgWrap" @click="jumpDetail(info)">
        <img
          :alt="info.name | defaultName"
          :src="info.images ? info.images + picSuffix : defaultImage"
        />
      </div>
      <h3 class="shopName" :title="info.name">
        {{ info.name | defaultName }}
        <span v-if="info.presellSubTitle">[{{ info.presellSubTitle }}]</span>
      </h3>
      <p class="msg">
        
        每人限购{{ info.comboUserTotal }}{{info.productList[0].unit}} 
        
      </p>

      
      <div class="priceLineBox">
        <div class="priceLineBoxL">
            <p>
              <span class="price">
                {{
                  info.auditUserStatus === 0
                    ? "***"
                    : Number(info.comboPrice).toFixed(2) | capitalize
                }}
              </span>
              <del class="delPrice">
                {{
                  info.auditUserStatus === 0
                    ? "***"
                    : info.comboOriginalPrice | capitalize
                }}
              </del>
            </p>
            <div class="">
              <!-- :show-text='false' -->
              <el-progress :percentage="percentage" :format="format"  ></el-progress>
            </div>
        </div>
        <div class="priceLineBoxR">
          <span class="AddBtnR" @click="jumpDetail(info)">立即购买</span>
        </div>
        <div class="clear"></div>
        
      </div>
      <p class="msgLine"
        v-if="new Date(this.info.beginDate).getTime() < new Date().getTime()"
      >
        进行中
      </p>
      <p class="msgLine" v-else>{{ info.beginDate }}开抢</p>
    </el-col>
  </div>
</template>

<style lang="less" scoped>
@import "~style/index.less";
/deep/.el-progress-bar {
  padding-right:0;
  height:10px;
  top:3px;
  position: relative;
  // margin-right: -90px;
  width:53px;
}
/deep/.el-progress-bar__inner {
  background-color: #FF3333;
}
/deep/.el-progress__text {
  margin-left:2px;
  line-height: 20px;
  margin-right:5px;
  float: left;
  font-size: 11px !important;;
}
.item {
  width: 228px;
  background: #fff;
  float: left;
  margin-top: 15px;
  margin-right: 15px;
  // padding-left:8px;
  // padding-right:8px;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  .AddBtn {
    position: absolute;
    bottom: 12px;
    right: 10px;
    width: 80px;
    text-align: center;
    padding: 5px 0;
    font-size: 14px;
    color: #fff;
    background: #ff3333;
  }
  &:hover {
    box-shadow: 10px 6px 6px -4px #dedada;
    border-radius: 7px;
  }

  .imgWrap {
    overflow: hidden;
    position: relative;
    .soldOut {
      display: block;
      width: 80px;
      height: 80px;
      z-index: 2;
      position: absolute;
      //   bottom:0px;
      //  right:0px;
      bottom: 24px;
      right: 3px;
    }
    .AdvanceSaleBg {
      display: block;
      width: 228px;
      height: 228px;
      z-index: 2;
      position: absolute;
      top: 0px;
      right: 0px;
      &:hover {
        box-shadow: 10px 6px 6px -4px #dedada;
      }
    }
    .bottomRightBox {
      position: absolute;
      bottom: 7px;
      right: 0;
      z-index: 3;
      width: 60%;
      color: #ffffff;
      font-size: 13px;
    }
    img {
      // display: inline-block;
      // width: 100%;
      // height: 150px;
      display: block;
      width: 228px;
      height: 228px;
      margin: 0 auto;
    }
  }
  .shopName {
    padding: 0 8px;
    margin-top: 10px;
    height: 22px;
    color: #333;
    font-size: 15px;
    line-height: 22px;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    span {
      font-weight: normal;
      font-size: 13px;
    }
  }
      .msgLine{
        width: 100%;
        color: #929598;
        font-size: 12px;
        text-indent:11px;
        line-height: 20px;
      }
  .priceLineBox{
    margin-top: 5px;
    margin-bottom: 5px;
    width: 100%;
    line-height: 26px;
    font-size: 12px;
    color: #999;
    .priceLineBoxL{
      padding-left:8px;
      float: left;
      width: calc( 100% - 90px );
      .price {
          color: #ff3333;
          font-size: 16px;
          font-weight: bold;
          display: inline-block;
        }
        .delPrice {
          // padding-left:0px;
          color: #929598;
          font-size: 12px;
        }
    }
    .priceLineBoxR{
      float: right;
      width:80px;
      position: relative;

      .AddBtnR {
        display: block;
        position: absolute;
        width:80px;
        text-align: center;
        right: 8px;
        top:4px;
        padding: 5px 0;
        font-size: 14px;
        color: #fff;
        background: #ff3333;
      }
      &:hover {
        box-shadow: 10px 6px 6px -4px #dedada;
        border-radius: 7px;
      }
     
    }
    .clear{
      clear: both;
    }
  }
  .priceBox {
    padding: 0 8px;
    margin-top: 5px;
    height: 26px;
    line-height: 26px;
    font-size: 12px;
    color: #999;
    .price {
      color: #ff3333;
      font-size: 16px;
      font-weight: bold;
      display: inline-block;
    }
    .delPrice {
      // padding-left:0px;
      color: #929598;
      font-size: 12px;
    }
  }
  .msg {
    padding: 0 8px;
    color: #929598;
    font-size: 12px;
    text-indent: 3px;
    line-height: 20px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom:2px solid #F0F0F0;
  }

  .specification {
    height: 20px;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    color: #999;
  }
  .validity {
    line-height: 20px;
    font-size: 12px;
    color: #999;
  }
  .hidePrice {
    color: #ff3333;
    line-height: 26px;
    padding-top: 6px;
    .price {
      color: #ff3333;
      font-size: 18px;
      line-height: 26px;
      font-weight: bold;
    }
    .bottomBox {
      float: right;
      font-size: 12px;
      color: #ff3333;
    }
  }
}
</style>

<script>
import defaultImage from "@/assets/defaultImage.png";
export default {
  name: "ActivityModuleItem",
  data() {
    return {
      picSuffix: localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      num: 0,
      defaultImage: defaultImage,
      activityType: "",
      url: "",
      percentage: 0,
      numPriceArr: [],
    };
  },
  props: {
    info: {
      type: Object,
    },
    ActivityType: {
      type: Number,
    },
  },
  //监听执行
  watch: {
    info: function (newVal) {
      if (newVal) {
        if (this.ActivityType == 1) {
          //预售
          this.url = this.info.pic.split(",")[0];
        } else {
          //限时购   套餐使用數量comboUsage /套餐总量comboTotal
          if (this.info.comboTotal == 0) {
            this.percentage = Number(0);
          } else {
            this.percentage = parseFloat(
              ((this.info.comboUsage / this.info.comboTotal) * 100).toFixed(2)
            );
          }
        }
      }
    },
  },
  methods: {
    format(percentage) {
      return `已售${percentage}%`;
    },
    jumpDetail(info) {
      if (this.ActivityType == 1) {
        //预售
        //  alert('预售')
        // this.$router.push({
        //   name: "ActivityModuleDetail",
        //   query: { promotionId: info.promotionId, ActivityType: 1 },
        // });
        const routeData = this.$router.resolve({
          name: "ActivityModuleDetail",
          query: { promotionId: info.promotionId, ActivityType: 1 },
        });
        window.open(routeData.href, '_blank');
      } else {
        //超级秒杀
        //  alert(info.id)
        //  console.log('超级秒杀超级秒杀',info)
        // this.$router.push({
        //   name: "ActivityModuleDetail",
        //   query: { id: info.id, ActivityType: 2 },
        // });
        const routeData = this.$router.resolve({
          name: "ActivityModuleDetail",
          query: { id: info.id, ActivityType: 2 },
        });
        window.open(routeData.href, '_blank');
      }
    },
  },
  computed: {},

  created() {
    this.activityType = this.ActivityType;
    // console.log(this.info)
    if (this.ActivityType == 1) {
      //预售
      this.url = this.info.pic.split(",")[0];
      if (this.info.numPrice && this.info.numPrice != "") {
        this.numPriceArr = JSON.parse(this.info.numPrice);
      }
    } else {
      //限时购   套餐使用數量comboUsage /套餐总量comboTotal
      if (this.info.comboTotal == 0) {
        this.percentage = Number(0);
      } else {
        this.percentage = parseFloat(
          ((this.info.comboUsage / this.info.comboTotal) * 100).toFixed(2)
        );
      }
    }
  },
  updated() {},
};
</script>
